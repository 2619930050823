<template>
    <fp-legal-manager :TargetId="participant.UserId" />
</template>
<script>
export default {
  name: "PrescriptionLegalTab",
  props: {
    participant: Object,
  },
};
</script>